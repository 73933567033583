(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name statistics.teams.controller:StatsTeamsCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.statistics.teams')
    .controller('StatsTeamsCtrl', StatsTeamsCtrl);

  function StatsTeamsCtrl($filter, $mdToast, TeamStatistics, categories, genders, seasons, currentSeason, teamStats) {
    var vm = this;
    vm.ctrlName = 'StatsTeamsCtrl';

    vm.teamStats = teamStats;

    vm.phases = _.uniq(_.pluck(vm.teamStats, 'phase'));
    vm.programmes = _.uniq(_.pluck(vm.teamStats, 'programme'));
    vm.seasons = seasons;

    vm.selectedPhase = undefined;
    vm.selectedProgramme = undefined;
    vm.selectedSeasonId = currentSeason.id;


    vm.myChartObject = {
      type: 'Table',
      displayed: false,
      data: {
        cols: buildCols(categories, genders),
        rows: buildRows(vm.teamStats, categories, genders)
      },
      options: {
        title: $filter('translate')('STATISTICS.TEAMS'),
        cssClassNames : {'headerCell': 'header-padding'}
      },
      formatters: {}
    };

    vm.changeSeason = function () {
      TeamStatistics.query({'season_id': vm.selectedSeasonId}, function (data) {
        vm.teamStats = data;
        vm.changeFilter();
      }, function (err) {
        var message = err.data.message || 'ERROR_UPDATE';
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')(message))
            .position('bottom left')
            .hideDelay(3000)
        );
      });
    };


    vm.changeFilter = function () {
      var filterParams = {};
      if (!_.isUndefined(vm.selectedPhase)) filterParams['phase'] = vm.selectedPhase;
      if (!_.isUndefined(vm.selectedProgramme)) filterParams['programme'] = vm.selectedProgramme;
      var filteredTeamStats = _.filter(vm.teamStats, filterParams);
      vm.myChartObject.data.rows = buildRows(filteredTeamStats, categories, genders);
    };

    vm.readyHandler = function (chartWrapper) {
      vm.htmlTable = chartWrapper.getChart().getContainer().childNodes[0].childNodes[0].childNodes[0];
    };

    function buildCols(categories, genders) {
      var cols = [];
      cols.push({id: 'sport', label: $filter('translate')('SPORT'), type: 'string'});

      _.forEach(categories, function (c) {
        _.forEach(genders, function (g) {
          var l = $filter('translate')('CATEGORIES.' + c.name) + ' ' + $filter('translate')(g.name);
          cols.push({id: c.name + '_' + g.name, label: l, type: 'number'});
        })
      });

      cols.push({id: 'total', label: $filter('translate')('TOTAL'), type: 'number'});
      return cols;
    }

    function buildRows(data, categories, genders) {
      var rows = [];
      var sports = _.uniq(_.pluck(data, 'sport'));

      _.forEach(sports, function (s) {
        var sportName = $filter('translate')('SPORTS.' + s);
        var row = buildRow(sportName, categories, genders, _.filter(data, 'sport', s));
        rows.push(row);
      });
      var totalName = $filter('translate')('TOTAL');
      var totalRow = buildRow(totalName, categories, genders, data);

      rows.push(totalRow);
      return rows;

    }

    function buildRow(rowName, categories, genders, data) {
      var values = [];
      values.push({v: rowName});
      var total = 0;

      _.forEach(categories, function (c) {
        _.forEach(genders, function (g) {

          var predicate = {'category': c.name, 'gender': g.name};
          var teams = _.filter(data, predicate);
          var numTeamIds = _.size(_.uniq(_.pluck(teams, 'teamId')));

          values.push({v: numTeamIds});
          total = total + numTeamIds;
        })
      });
      values.push({v: total});
      return {c: values};
    }
  }
}());
